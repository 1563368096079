import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchManagerjustifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/justifications', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchManagerjustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/justifications/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateManagerjustification(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/justifications/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addManagerjustification(ctx, justificationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/justifications', justificationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteManagerjustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/justifications/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    approveManagerjustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/justifications/approve/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    rejectManagerjustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/justifications/reject/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchManagerjustificationTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/justification-types', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
