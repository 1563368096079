import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default function useManagerjustificationsList() {
  // Use toast
  const toast = useToast()

  const refManagerjustificationsListTable = ref(null)

  const userData = getUserData()

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'utente', sortable: true },
    { key: 'periodo', sortable: true },
    { key: 'tipo', sortable: true },
    { key: 'stato', sortable: true },
    { key: 'azioni', sortable: false },
  ]
  const fields = {
    id: 'id',
    utente: 'user_id',
    periodo: 'start_day',
    tipo: 'type',
    stato: 'status',
  }
  const perPage = ref(10)
  const totalManagerjustifications = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const approvationType = ref('two_managers')
  const statusFilter = ref(null)
  const typeFilter = ref(null)
  const userFilter = ref(null)
  const departmentFilter = ref(null)
  const subDepartmentFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refManagerjustificationsListTable.value
      ? refManagerjustificationsListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalManagerjustifications.value,
    }
  })

  const refetchData = () => {
    refManagerjustificationsListTable.value.refresh()
  }

  watch([currentPage, perPage, statusFilter, typeFilter, userFilter, departmentFilter, subDepartmentFilter], () => {
    refetchData()
  })

  const fetchManagerjustifications = (ctx, callback) => {
    store
      .dispatch('app-managerjustifications/fetchManagerjustifications', {
        approvation_type: approvationType.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        status: statusFilter.value,
        type: typeFilter.value,
        user_id: userFilter.value,
        department_id: subDepartmentFilter.value || departmentFilter.value,
      })
      .then(response => {
        const managerjustifications = response.data[0]
        const total = response.data[1]

        callback(managerjustifications)
        totalManagerjustifications.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  const typeOptions = ref(null)
  const _types = []
  store
    .dispatch('app-managerjustifications/fetchManagerjustificationTypes', {})
    .then(response => {
      response.data[0].forEach((type, i) => {
        const _type = {
          label: type.name.charAt(0).toUpperCase() + type.name.slice(1),
          value: type.name,
        }
        _types.push(_type)
      })
      // const _type = {
      //   label: 'Ferie Approvate',
      //   value: 'Ferie Approvate'
      // }
      // _types.push(_type)
    })
    .catch(error => {
      if (error === 401) {
        localStorage.removeItem('userData')
        store.commit('user/updateUserData', null)
        router.replace({ name: 'auth-login' }).then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sessione scaduta',
              icon: 'AlertTriangleIcon',
              text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
              variant: 'danger',
            },
          })
        })
      }
    })
  typeOptions.value = _types

  const departmentOptions = ref(null)
  const _departments = []
  store
    .dispatch('app-departments/fetchDepartments', { root: true, for_approvation: 1 })
    .then(response => {
      response.data[0].forEach((department, i) => {
        const _department = {
          label: `#${department.id} - ${department.name}`,
          value: department.id,
        }
        _departments.push(_department)
      })
    })
    .catch(error => {
      if (error === 401) {
        localStorage.removeItem('userData')
        store.commit('user/updateUserData', null)
        window.location.reload(true)
      }
    })
  departmentOptions.value = _departments

  const subDepartmentOptions = ref([])
  watch(departmentFilter, () => {
    if (!departmentFilter.value) {
      subDepartmentFilter.value = null
      subDepartmentOptions.value = []
      return
    }
    store
      .dispatch('app-departments/fetchDepartments', {
        parent_department_id: departmentFilter.value,
        per_page: 1000,
        for_approvation: 1,
      })
      .then(response => {
        subDepartmentOptions.value = response.data[0].map(department => ({
          label: `#${department.id} - ${department.name}`,
          value: department.id,
        }))
      })
      .catch(() => {})
  })

  const userOptions = ref(null)

  const setUserOptions = async () => {
    userFilter.value = null
    const _users = []
    try {
      const response = await store.dispatch('app-user/fetchUsers', {
        per_page: 1000,
        for_approvation: 1,
        department_id: subDepartmentFilter.value || departmentFilter.value,
      })
      response.data[0].forEach((user, i) => {
        const _user = {
          label: `#${user.anagrafico_id} - ${user.display_name}`,
          value: user.id,
        }
        _users.push(_user)
      })
      userOptions.value = _users
    } catch (error) {
      if (error === 401) {
        // Gestisci l'errore 401 qui
      }
    }
  }

  setUserOptions()

  watch([departmentFilter, subDepartmentFilter], () => {
    setUserOptions()
  })

  const statusOptions = ref([
    { label: 'In Attesa', value: 'waiting' },
    { label: 'Approvato', value: 'approved' },
    { label: 'Rifiutato', value: 'rejected' },
  ])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusName = role => {
    if (role === 'waiting') return 'In attesa'
    if (role === 'approved') return 'Approvato'
    if (role === 'rejected') return 'Rifiutato'
    return 'Sconosciuto'
  }

  const resolveStatusVariant = role => {
    if (role === 'waiting') return 'secondary'
    if (role === 'approved') return 'success'
    if (role === 'rejected') return 'danger'
    return 'warning'
  }

  const resolveStatusIcon = role => {
    if (role === 'waiting') return 'PauseIcon'
    if (role === 'approved') return 'CheckIcon'
    if (role === 'rejected') return 'XIcon'
    return 'UserIcon'
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const parseDate = date => moment(date).format('DD/MM/YYYY')

  return {
    fetchManagerjustifications,
    tableColumns,
    perPage,
    currentPage,
    totalManagerjustifications,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refManagerjustificationsListTable,
    userData,

    resolveStatusName,
    resolveStatusVariant,
    resolveStatusIcon,
    resolveUserRoleVariant,
    parseDate,

    refetchData,

    statusFilter,
    typeFilter,
    userFilter,
    departmentFilter,
    subDepartmentFilter,

    statusOptions,
    typeOptions,
    userOptions,
    departmentOptions,
    subDepartmentOptions,
  }
}
