<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="pt-2">
          <b-col
            class="mb-4 d-flex justify-content-center align-items-center"
            cols="12"
          >
            <feather-icon
              icon="ClipboardIcon"
              size="25"
              class="mr-2"
            />
            <h1>Richieste Ferie Utenti</h1>
          </b-col>
        </b-row>
        <managerjustifications-list-filters
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
          :type-filter.sync="typeFilter"
          :type-options="typeOptions"
          :user-filter.sync="userFilter"
          :user-options="userOptions"
          :department-options="departmentOptions"
          :department-filter.sync="departmentFilter"
          :sub-department-options="subDepartmentOptions"
          :sub-department-filter.sync="subDepartmentFilter"
        />
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refManagerjustificationsListTable"
        class="position-relative"
        :items="fetchManagerjustifications"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Giustificativo corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(utente)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.user ? mediaUrl + data.item.user.avatar_file : ''"
                :text="avatarText(data.item.user ? data.item.user.display_name : 'XX')"
                :variant="`light-${resolveUserRoleVariant(data.item.user ? data.item.user.role_code : '')}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.user ? data.item.user.display_name : 'XX' }}
            </span>
            <small class="text-muted">@{{ data.item.user ? data.item.user.identifier: 'XX' }}</small>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(periodo)="data">
          <div
            v-if="data.item.all_day && data.item.start_day == data.item.end_day"
            class="text-nowrap"
          >
            <feather-icon
              icon="CalendarIcon"
              class="mr-1 text-primary"
            />
            <span class="align-text-top">{{ parseDate(data.item.start_day) }}</span>
          </div>
          <div
            v-if="data.item.all_day && data.item.start_day != data.item.end_day"
            class="text-nowrap"
          >
            <feather-icon
              icon="CalendarIcon"
              class="mr-1 text-primary"
            />
            <span class="align-text-top">{{ parseDate(data.item.start_day) }} - {{ parseDate(data.item.end_day) }}</span>
          </div>
          <div
            v-if="!data.item.all_day && data.item.start_day == data.item.end_day"
            class="text-nowrap"
          >
            <feather-icon
              icon="CalendarIcon"
              class="mr-1 text-primary"
            />
            <span class="align-text-top">{{ parseDate(data.item.start_day) }} {{ data.item.start_time.substring(0,5) }} - {{ data.item.end_time.substring(0,5) }}</span>
          </div>
          <div
            v-if="!data.item.all_day && data.item.start_day != data.item.end_day"
            class="text-nowrap"
          >
            <feather-icon
              icon="CalendarIcon"
              class="mr-1 text-primary"
            />
            <span class="align-text-top">{{ parseDate(data.item.start_day) }} {{ data.item.start_time.substring(0,5) }} - {{ parseDate(data.item.end_day) }} {{ data.item.end_time.substring(0,5) }}</span>
          </div>
        </template>

        <template #cell(tipo)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
          </div>
        </template>

        <template #cell(stato)="data">
          <div
            v-if="data.item.status == 'waiting' &&
              (data.item.approved_first_user_id && data.item.approved_first_user_id == userData.id || data.item.approved_second_user_id && data.item.approved_second_user_id == userData.id)"
            class="text-nowrap"
          >
            <feather-icon
              icon="ClockIcon"
              size="18"
              class="mr-50 text-warning"
            />
            <span class="align-text-top text-capitalize">Attesa 2ª Approvazione</span>
          </div>
          <div
            v-else
            class="text-nowrap"
          >
            <feather-icon
              :icon="resolveStatusIcon(data.item.status)"
              size="18"
              class="mr-50"
              :class="`text-${resolveStatusVariant(data.item.status)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveStatusName(data.item.status) }}</span>
          </div>
        </template>
        <template
          v-if="userData.role_code !== 'hr'"
          #cell(azioni)="row"
        >
          <div class="d-flex align-items-center">
            <b-button
              v-if="row.item.note && !row.detailsShowing"
              title="Visualizza Note"
              size="sm"
              variant="primary"
              class="rounded-circle"
              style="padding: 6px"
              @click="
                row.toggleDetails()
              "
            >
              <feather-icon
                icon="ChevronDownIcon"
                size="15"
              />
            </b-button>
            <b-button
              v-if="row.item.note && row.detailsShowing"
              title="Nascondi Note"
              size="sm"
              variant="primary"
              class="rounded-circle"
              style="padding: 6px"
              @click="
                row.toggleDetails()
              "
            >
              <feather-icon
                icon="ChevronUpIcon"
                size="15"
              />
            </b-button>
            <b-button
              v-if="row.item.status =='waiting' &&
                (row.item.approved_first_user_id != userData.id && row.item.approved_second_user_id != userData.id)"
              title="Approva"
              size="sm"
              variant="success"
              class="ml-1 rounded-circle"
              style="padding: 6px"
              @click="
                $bvModal.show('approve-modal')
                selectedJustification = row.item
              "
            >
              <feather-icon
                icon="CheckIcon"
                size="15"
              />
            </b-button>
            <b-button
              v-if="row.item.status =='waiting' &&
                (row.item.approved_first_user_id != userData.id && row.item.approved_second_user_id != userData.id)"
              title="Rifiuta"
              size="sm"
              variant="danger"
              class="ml-1 rounded-circle"
              style="padding: 6px"
              @click="
                $bvModal.show('reject-modal')
                selectedJustification = row.item
              "
            >
              <feather-icon
                icon="XIcon"
                size="15"
              />
            </b-button>
          </div>
        </template>
        <template #row-details="row">
          <div
            class="rounded-lg p-1 mb-1"
            style="background-color: #161d31"
          >
            <p class="m-0">
              {{ row.item.note }}
            </p>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalManagerjustifications"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="approve-modal"
        title="Approva Richieste Ferie Utente"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Approva Richieste Ferie Utente
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler approvare definitivamente questo Richiesta Ferie Utente? (azione irreversibile)
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              approveJustification(selectedJustification)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
      <b-modal
        id="reject-modal"
        title="Rifiuta Richieste Ferie Utente"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Rifiuta Richieste Ferie Utente
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler rifiutare definitivamente questo Richiesta Ferie Utente? (azione irreversibile)
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              rejectJustification(selectedJustification)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/apps/user/userStoreModule'
import departmentsStoreModule from '@/views/apps/departments/departmentsStoreModule'
import useManagerjustificationsList from './useManagerjustificationsList'
import managerjustificationsStoreModule from '../managerjustificationsStoreModule'
import ManagerjustificationsListFilters from './ManagerjustificationsListFilters.vue'

export default {
  components: {
    ManagerjustificationsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BAvatar,

    vSelect,
  },
  setup() {
    const MANAGERJUSTIFICATIONS_APP_STORE_MODULE_NAME = 'app-managerjustifications'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(MANAGERJUSTIFICATIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(MANAGERJUSTIFICATIONS_APP_STORE_MODULE_NAME, managerjustificationsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MANAGERJUSTIFICATIONS_APP_STORE_MODULE_NAME)) { store.unregisterModule(MANAGERJUSTIFICATIONS_APP_STORE_MODULE_NAME) }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const {
      fetchManagerjustifications,
      tableColumns,
      perPage,
      currentPage,
      totalManagerjustifications,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refManagerjustificationsListTable,
      userData,
      refetchData,

      resolveStatusName,
      resolveStatusVariant,
      resolveStatusIcon,
      resolveUserRoleVariant,
      parseDate,

      statusFilter,
      typeFilter,
      userFilter,
      departmentFilter,

      statusOptions,
      typeOptions,
      userOptions,
      departmentOptions,
      subDepartmentOptions,
      subDepartmentFilter,

    } = useManagerjustificationsList()

    return {
      fetchManagerjustifications,
      tableColumns,
      perPage,
      currentPage,
      totalManagerjustifications,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refManagerjustificationsListTable,
      userData,
      refetchData,

      resolveStatusName,
      resolveStatusVariant,
      resolveStatusIcon,
      resolveUserRoleVariant,
      parseDate,

      // Filter
      avatarText,
      mediaUrl,

      statusFilter,
      typeFilter,
      userFilter,
      departmentFilter,
      subDepartmentFilter,

      statusOptions,
      typeOptions,
      userOptions,
      departmentOptions,
      subDepartmentOptions,

      selectedJustification: null,
    }
  },
  mounted() {
    const { approvedId } = this.$router.currentRoute.params
    if (approvedId && approvedId !== undefined) {
      this.approveJustification({ id: approvedId })
    }
  },
  methods: {
    approveJustification(justification) {
      const { id } = justification
      store
        .dispatch('app-managerjustifications/approveManagerjustification', { id })
        .then(() => {
          this.refetchData()
          this.$bvModal.hide('approve-modal')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giustificativo Approvato con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
          if (error === 406) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Errore Email',
                icon: 'AlertTriangleIcon',
                text: 'Email utente non valida, impossibile procedere.',
                variant: 'danger',
              },
            })
          }
        })
    },
    rejectJustification(justification) {
      const { id } = justification
      store
        .dispatch('app-managerjustifications/rejectManagerjustification', { id })
        .then(() => {
          this.refetchData()
          this.$bvModal.hide('reject-modal')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giustificativo Rifiutato con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
          if (error === 406) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Errore Email',
                icon: 'AlertTriangleIcon',
                text: 'Email utente non valida, impossibile procedere.',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
